import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

export const FaqSection = ({ slice }) => {
  return (
    <section className="faq_section">
      <div className="flex_content">
        <div className="page_title">
          <PrismicRichText field={slice.primary.header.richText}/>
        </div>
        {slice.items.map((galleryItem, index) => (
          <details>
            <summary>
              <PrismicRichText field={galleryItem.item_header.richText}/>
            </summary>
            <PrismicRichText field={galleryItem.item_text.richText}/>
          </details>
        ))}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment FaqDataBodyFaqSection on PrismicFaqDataBodyFaqSection {
    primary {
      header {
        richText
      }
    }
    items{
      item_header{
        richText
      }
      item_text{
        richText
      }
    }
  }
    
  fragment SongwriterAiPageDataBodyFaqSection on PrismicSongwriterAiPageDataBodyFaqSection {

    primary {
      header {
        richText
      }
    }
    items{
      item_header{
        richText
      }
      item_text{
        richText
      }
    }

  }
`
