import React, { useState } from "react"
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

export const Newsletter = ({ slice }) => {

  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();
    // Mailchimp always responds with status code 200, accompanied by a string indicating the result of the response.
    const { result, msg } = await addToMailchimp(email);
    result === 'success' && setEmail('');
    // Removes the HTML returned in some response messages in case of error
    setMessage(msg.split('<')[0]);
    setStatus(result);
  };

  const handleChange = event => setEmail(event.target.value);

  return (
    <section id="newsletter" className="newsletter">
      <div className="Container">
        <div className="wrap"> 
          <div className="flex_container">
            <PrismicRichText field={slice.primary.header.richText} />
            <PrismicRichText field={slice.primary.text.richText} />

            <form>
              <div>
                <label htmlFor="mce-EMAIL">{slice.primary.email_label}</label>
                <input 
                  onChange={handleChange}
                  type="email" 
                  name="EMAIL" 
                  id="mce-EMAIL" 
                  required
                  className="email_input" />
              </div>  
              <input onClick={handleSubmit} type="submit" name="subscribe" value={slice.primary.sign_up_text} className="button_primary button" id="mc-embedded-subscribe" />
            
              <p status={status}>
                {message}
              </p>

            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyNewsletter on PrismicHomepageDataBodyNewsletter {
    primary {
      header {
        richText
      }
      email_label
      sign_up_text
      text {
        richText
      }
    }
  }
  fragment TeacherChecklistDataBodyNewsletter on PrismicTeacherChecklistDataBodyNewsletter {
    primary {
      header {
        richText
      }
      email_label
      sign_up_text
      text {
        richText
      }
    }
  }
  fragment SongwriterAiPageDataBodyNewsletter on PrismicSongwriterAiPageDataBodyNewsletter {
    primary {
      header {
        richText
      }
      email_label
      sign_up_text
      text {
        richText
      }
    }
  }
`
