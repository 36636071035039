const { defaultLanguage } = require('../../prismic-configuration')

/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 *
 * @type import('@prismicio/helpers').LinkResolverFunction
 */
exports.linkResolver = (doc) => {
  switch (doc.type) {
    case 'homepage': {
      return doc.lang === defaultLanguage ? `/` : `/${doc.lang}`
    }

    case 'page': {
      return doc.lang === defaultLanguage
        ? `/page/${doc.uid}`
        : `/page/${doc.lang}/${doc.uid}`
    }

    case 'faq': {
      return doc.lang === defaultLanguage ? `/faq` : `/${doc.lang}/faq`
    }
    case 'contact': {
      return doc.lang === defaultLanguage ? `/contact` : `/${doc.lang}/contact`
    }
    case 'teacher_checklist': {
      return doc.lang === defaultLanguage
        ? `/teacher_checklist`
        : `/${doc.lang}/teacher_checklist`
    }
    case 'teacher_training': {
      return doc.lang === defaultLanguage
        ? `/teacher_training`
        : `/${doc.lang}/teacher_training`
    }
    case 'module_overview': {
      return doc.lang === defaultLanguage
        ? `/${doc.uid}`
        : `/${doc.lang}/${doc.uid}`
    }
    case 'module_lesson': {
      const parent = doc.data ? doc.data.parent.uid : 'module'
      return doc.lang === defaultLanguage
        ? `/${parent}/${doc.uid}`
        : `/${doc.lang}/${parent}/${doc.uid}`
    }
    case 'judges': {
      return doc.lang === defaultLanguage
        ? `/${doc.uid}`
        : `/${doc.lang}/${doc.uid}`
    }
    case 'winners': {
      return doc.lang === defaultLanguage
        ? `/winner-and-judges/${doc.uid}`
        : `/${doc.lang}/winner-and-judges/${doc.uid}`
    }
    case 'songwriter_ai_page': {
      return doc.lang === defaultLanguage
        ? `/ai/${doc.uid}`
        : `/${doc.lang}/ai/${doc.uid}`
    }
    case 'songwriter_ai_hub': {
      return doc.lang === defaultLanguage
        ? `/ai`
        : `/${doc.lang}/ai`
    }
    default:
      return `/${doc.uid}`
  }
}
