import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { HtmlSerializer } from '../utils/htmlSerializer'

export const TextSection = ({ slice }) => {
  const id = slice.primary.activity_anchor || ''

  return (
    <section className="text_section">
      <div className="Container">
        <div className="anchor" id={id} />
        <div className="text_box">
          <PrismicRichText
            field={slice.primary.text_field.richText}
            components={HtmlSerializer}
          />
          <div className="grid">
            {slice.items?.length !== 0 &&
              slice.items?.map((item, index) => (
                <div key={index} className="WinnerCards___card">
                  <div className="filter-text">
                    <PrismicRichText field={item.filter_title_1.richText} />
                    <PrismicRichText field={item.filter_body_1.richText} />
                  </div>
                  <div className="filter-text">
                    <PrismicRichText field={item.filter_title_2.richText} />
                    <PrismicRichText field={item.filter_body_2.richText} />
                  </div>
                  <div className="filter-text">
                    <PrismicRichText field={item.filter_title_3.richText} />
                    <PrismicRichText field={item.filter_body_3?.richText} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment ModuleLessonDataBodyTextSection on PrismicModuleLessonDataBodyTextSection {
    primary {
      text_field {
        richText
      }
      activity_anchor
    }
  }
  fragment SongwriterAiPageDataBodyTextSection on PrismicSongwriterAiPageDataBodyTextSection {
    primary {
      text_field {
        richText
      }
      activity_anchor
    }
    items {
      filter_title_1 {
        richText
      }
      filter_body_1 {
        richText
      }
      filter_title_2 {
        richText
      }
      filter_body_2 {
        richText
      }
      filter_title_3 {
        richText
      }
      filter_body_3 {
        richText
      }
    }
  }
  fragment SongwriterAiHubDataBodyTextSection on PrismicSongwriterAiHubDataBodyTextSection {
    primary {
      text_field {
        richText
      }
      activity_anchor
    }
  }
`
