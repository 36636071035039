import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { HtmlSerializer } from '../utils/htmlSerializer'


export const ImageText = ({ slice }) => {
  const id = slice.primary?.activity_anchor || ""
  function alignment(){
    if (slice.primary?.image_alignment) return "flex_box right"
    else return "flex_box left"
  }
  return (
    <section className="image_text">
      <div className="anchor" id={id}/>
      <div className={alignment()}>
        <div className="text_box">
          <PrismicRichText 
            field={slice.primary?.text_field?.richText} 
            components={HtmlSerializer}
          />
        </div>
        <GatsbyImage
          image={slice.primary?.image?.gatsbyImageData}
          alt={slice.primary?.image?.alt || ""}
          className="image_box"
        />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment ModuleLessonDataBodyImageText on PrismicModuleLessonDataBodyImageText {
    primary {
      image_alignment
      text_field {
        richText
      }
      image{
        url
        alt
        gatsbyImageData
      }
      activity_anchor
    }
  }
  fragment SongwriterAiPageDataBodyImageText on PrismicSongwriterAiPageDataBodyImageText {
   primary {
      image_alignment
      text_field {
        richText
      }
      image{
        url
        alt
        gatsbyImageData
      }
      activity_anchor
    }
}
`
